import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {path: '/', name: 'Home', redirect:'/login',meta:{title:"首页"},},
  {path: '/home', name: 'Home', component: () => import('../views/home/Home.vue'),meta:{title:"居民果果"},},
  {path: '/newHome', name: 'newHome', component: () => import('../views/home/newHome.vue'),meta:{title:"居民果果"},},
  {path: '/newOldHome', name: 'newOldHome', component: () => import('../views/home/newOldHome.vue'),meta:{title:"居民果果"},},
  {path: '/my', name: 'my', component: () => import('../views/my/my.vue') ,meta:{title:"我的"},},
  {path: '/sso', name: 'sso', component: () => import('../views/common/SSO.vue'),meta:{title:"加载中"},},

  {path: '/my-info', name: 'my-info', component: () => import('../views/my/my-info.vue') ,meta:{title:"更多信息"},},
  {path: '/my-info-change', name: 'my-info-change', component: () => import('../views/my/my-info-change.vue') ,meta:{title:"修改信息"},},
  {path: '/my-qrcode', name: 'my-qrcode', component: () => import('../views/my/my-qrcode.vue') ,meta:{title:"居民果果"},},
  {path: '/mobile-change', name: 'mobile-change', component: () => import('../views/my/mobile-change.vue') },
  {path: '/notice', name: 'notice', component: () => import('../views/notice/notice.vue'),meta:{title:"公告"}, },
  {path: '/notice-info', name: 'notice-info', component: () => import('../views/notice/notice-info.vue'),meta:{title:"公告详情"},},
  {path: '/guide', name: 'guide', component: () => import('../views/guide/guide.vue') ,meta:{title:"办事指南"},},
  {path: '/guide-info', name: 'guide-info', component: () => import('../views/guide/guide-info.vue'),meta:{title:"指南详情", keepAlive: true},},
  {path: '/consult-info', name: 'consult-info', component: () => import('../views/guide/consult-info.vue'), meta:{title: '咨询详情'} },
  {path: '/consult-add', name: 'consult-add', component: () => import('../views/guide/consult-add.vue'), meta:{title: '我要咨询'} },
  {path: '/problem', name: 'problem', component: () => import('../views/problem/problem.vue') ,meta:{title:"问题"},},
  {path: '/problem-info', name: 'problem-info', component: () => import('../views/problem/problem-info.vue'),meta:{title:"问题详情"},},
  {path: '/problem-add', name: 'problem-add', component: () => import('../views/problem/problem-add.vue'),meta:{title:"新增问题"},},

  {path: '/login', name: 'login', component: () => import('../views/common/login.vue') ,meta:{title:"登录"},},
  {path: '/community-change', name: 'community-change', component: () => import('../views/common/community-change.vue') ,meta:{title:"选择社区"},},
  {path: '/nation', name: 'nation', component: () => import('../views/nation/nation.vue') ,meta:{title:"", fromRefresh: 0}},
  {path: '/nationality', name: 'nationality', component: () => import('../views/nation/nationality.vue') ,meta:{title:"", fromRefresh: 0},},

  {path: '/tabulation', name: 'tabulation', component: () => import('../views/questionnaire/tabulation.vue'), meta:{title: '问卷'} },
  {path: '/questionnaire', name: 'questionnaire', component: () => import('../views/questionnaire/questionnaire.vue'), meta:{title: '问卷'} },
  {path: '/questionInfo', name: 'questionInfo', component: () => import('../views/questionnaire/questionInfo.vue'), meta:{title: '问卷'} },
  {path: '/answer-info', name: 'answer-info', component: () => import('../views/questionnaire/answer-info.vue'), meta:{title: '问卷'} },
  {path: '/questionList', name: 'questionList', component: () => import('../views/questionnaire/questionList.vue'), meta:{title: '问卷'} },
  {path: '/questionWrite', name: 'questionWrite', component: () => import('../views/questionnaire/questionWrite.vue'), meta:{title: '问卷'} },

  {path: '/rescue-list', name: 'rescue-list',  component: () => import('../views/rescue-station/list.vue'),meta:{title:"社会救助站"}},
  {path: '/rescue-add', name: 'rescue-add',  component: () => import('../views/rescue-station/add.vue'),meta:{title:"我要求助"}},
  {path: '/rescue-detail', name: 'rescue-detail',  component: () => import('../views/rescue-station/detail.vue'),meta:{title:"求助详情"}},
  {path: '/rescue-evaluate', name: 'rescue-evaluate',  component: () => import('../views/rescue-station/evaluate.vue'),meta:{title:"查看评价"}},


  { path: '/workGoals', name: 'workGoals', component: () => import('../views/workGoals/workGoals.vue') ,meta:{title:"工作目标"},},
  { path: '/recentWork', name: 'recentWork', component: () => import('../views/workGoals/recentWork.vue') ,meta:{title:"居民果果"},},
  { path: '/recentWorkInfo/:userId', name: 'recentWorkInfo', component: () => import('../views/workGoals/recentWorkInfo.vue') ,meta:{title:"居民果果"}},

  { path: '/groupTogeter', name: 'groupTogeter', component: () => import('../views/groupTogeter/groupTogeter.vue') ,meta:{title:"群团聚力"},},
  { path: '/union', name: 'union', component: () => import('../views/groupTogeter/union.vue') ,meta:{title:"居民果果"}},

  { path: '/thereServiceOpen', name: 'thereServiceOpen', component: () => import('../views/thereServiceOpen/thereServiceOpen.vue') ,meta:{title:"三务公开"},},
  { path: '/appServiceOpen', name: 'appServiceOpen', component: () => import('../views/thereServiceOpen/appServiceOpen.vue') ,meta:{title:"三务公开"},},
  { path: '/openVillage', name: 'openVillage', component: () => import('../views/thereServiceOpen/openVillage.vue') ,meta:{title:"三务公开"}},
  { path: '/openLabour', name: 'openLabour', component: () => import('../views/thereServiceOpen/openLabour.vue') ,meta:{title:"三务公开"}},

  {path: '/wisdom', name: 'wisdom', component: () => import('../views/buildStrong/wisdom.vue') ,meta:{title:"居民果果"},},
  {path: '/wisdom-info', name: 'wisdom-info', component: () => import('../views/buildStrong/wisdom-info.vue'),meta:{title:"居民果果"}, },
  {path: '/trifle', name: 'trifle', component: () => import('../views/buildStrong/trifle.vue'),meta:{title:"居民果果"}, },
  {path: '/trifle-info', name: 'trifle-info', component: () => import('../views/buildStrong/trifle-info.vue'),meta:{title:"居民果果"},},
  {path: '/trifle-add', name: 'trifle-add', component: () => import('../views/buildStrong/trifle-add.vue'),meta:{title:"居民果果"},},
  {path: '/trifle-handling', name: 'trifle-handling', component: () => import('../views/buildStrong/trifle-handling.vue'),meta:{title:"居民果果"},},
  {path: '/trifle-change', name: 'trifle-change', component: () => import('../views/buildStrong/trifle-change.vue'),meta:{title:"居民果果"},},

  {path: '/grip', name: 'grip', component: () => import('../views/future/grip/grip.vue'),meta:{title:"握一握"},},
  {path: '/grip-add', name: 'grip-add', component: () => import('../views/future/grip/grip-add.vue'),meta:{title:"新增握一握"},},
  {path: '/grip-info', name: 'grip-info', component: () => import('../views/future/grip/grip-info.vue'),meta:{title:"握一握详情"},},

  {path: '/reminder', name: 'reminder', component: () => import('../views/future/reminder/reminder.vue'),meta:{title:"提个醒"},},
  {path: '/reminder-add', name: 'reminder-add', component: () => import('../views/future/reminder/reminder-add.vue'),meta:{title:"新增提个醒"},},
  {path: '/reminder-info', name: 'reminder-info', component: () => import('../views/future/reminder/reminder-info.vue'),meta:{title:"提个醒详情"},},

  {path: '/parking', name: 'parking', component: () => import('../views/future/parking/parking.vue'),meta:{title:"停车引导"},},

  //老人服务----请求者
  { path: '/older-service-request', name: 'old-service-request', component: () => import('../views/older-service/request/older-service'),meta:{title:"人人为我"}, },
//服务单详情--请求者
  { path: '/request-detail-request', name: 'request-detail-request', component: () => import('../views/older-service/request/request-detail'),meta:{title:"服务单详情"}, },
//老人服务----服务者
  { path: '/older-service-server', name: 'oldServiceServer',component: () => import('../views/older-service/server/older-service'),meta:{title:"我为人人"},},
//服务单详情--服务者
  { path: '/request-detail-server', name: 'request-detail-server', component: () => import('../views/older-service/server/request-detail'),meta:{title:"服务单详情"}, },
//积分详情
  {path: '/integral-detail', name: 'integral-detail', component: () => import('../views/integral-detail/integral-detail'),meta:{title:"积分详情"},},
//积分兑换
  {path: '/integral-change', name: 'integral-change', component: () => import('../views/integral-change/integral-change'),meta:{title:"积分兑换"},},
//请求服务弹窗
  {path: '/request-confirm', name: 'request-confirm', component: () => import('../views/win/request-confirm'),meta:{title:"停车引导"},},
//申请成功弹窗
  {path: '/service-apply', name: 'service-apply', component: () => import('../views/win/service-apply'),meta:{title:"停车引导"},},
//免责声明弹窗
  {path: '/disclaimer', name: 'disclaimer', component: () => import('../views/win/disclaimer'),meta:{title:"免责声明"},},
//申请超过数量弹窗
  {path: '/audit-number', name: 'audit-number', component: () => import('../views/win/audit-number'),meta:{title:"停车引导"},},
//等待审核弹窗
  {path: '/auditing', name: 'auditing', component: () => import('../views/win/auditing')},

  {path: '/404', name: '404', component: () => import('../views/common/404.vue') ,meta:{title:"404"},},
  {path: '/common-func', name: 'common-func', component: () => import('../views/common/common-func'),meta:{title: '功能配置'}},
  // {path: '/form', name: 'form', component: () => import('../views/form/form.vue') ,meta:{title:"表单"},},
  {path: '/finished', name: 'finished', component: () => import('../views/form/finished.vue') ,meta:{title:"表单"},},
  {path: '/form', name: 'form', component: () => import('../views/myApp/form.vue') },
  //  闸门人大
  {path: '/entrance', name: 'entrance', component: () => import('../views/wePeople/entrance.vue'),meta:{title:"闸门人大"},},
  {path: '/sideNotice', name: 'sideNotice', component: () => import('../views/wePeople/sideNotice.vue'),meta:{title:"公告通知"},},
  {path: '/sideNotice-info', name: 'sideNotice-info', component: () => import('../views/wePeople/sideNotice-info.vue'),meta:{title:"公告详情"},},
  {path: '/peopleMail', name: 'peopleMail', component: () => import('../views/wePeople/peopleMail.vue'),meta:{title:"人大信箱"},},
  {path: '/peopleMail-info', name: 'peopleMail-info', component: () => import('../views/wePeople/peopleMail-info.vue'),meta:{title:"添加提议"},},
  {path: '/peopleSide', name: 'peopleSide', component: () => import('../views/wePeople/peopleSide.vue'),meta:{title:"人大在身边"},},
  {path: '/peopleStudio', name: 'peopleStudio', component: () => import('../views/wePeople/peopleStudio.vue'),meta:{title:"代表工作室"},},
  {path: '/peopleDetail', name: 'peopleDetail', component: () => import('../views/wePeople/peopleDetail.vue'),meta:{title:"代表详情"},},
  {path: '/peopleJob-info', name: 'peopleJob-info', component: () => import('../views/wePeople/peopleJob-info.vue'),meta:{title:"工作与活动详情"},},

  {path: '/health-punch', name: 'health-punch', component: () => import('../views/epidemic-prevention/health-punch.vue'),meta:{title:"健康打卡"},},
  {path: '/punch-result', name: 'punch-result', component: () => import('../views/epidemic-prevention/punch-result.vue'),meta:{title:"健康打卡"},},
  {path: '/reportNeed', name: 'reportNeed', component: () => import('../views/epidemic-prevention/reportNeed.vue'),meta:{title:"需求上报"},},
  {path: '/reportMedical', name: 'reportMedical', component: () => import('../views/epidemic-prevention/reportMedical.vue'),meta:{title:"疾病报备"},},
  {path: '/reportAcid', name: 'reportAcid', component: () => import('../views/epidemic-prevention/reportAcid.vue'),meta:{title:"核酸上报"},},
  // {path: '/receive-register', name: 'receiveRegister', component: () => import('../views/receiveCode/receive-register.vue'),meta:{title:"居民登记"},},
  {path: '/receive-code', name: 'receiveCode', component: () => import('../views/receiveCode/receive-code.vue'),meta:{title:"居民通行证"},},
  {path: '/handle-business', name: 'handleBusiness', component: () => import('../views/handleBusiness/handle-business.vue'),meta:{title:"办理业务"},},

  {path: '/myApp', name: 'myApp', component: () => import('../views/myApp/index.vue') },
  {path: '/appSSO', name: 'appSSO', component: () => import('../views/myApp/appSSO.vue'),meta:{title:"居民果果"}, },

  {path: '/404', name: '404', component: () => import('../views/common/404.vue') ,meta:{title:"404"},},
  {path: '/partyActiveSign', name: 'result-info', component: () => import('../views/common/partyActiveSign.vue') ,meta:{title:"活动签到"}},
  {path: '/activity', name: 'activity', component: () => import('../views/activity/list.vue'), meta:{title: '活动'} },
  {path: '/activity-info', name: 'activity-info', component: () => import('../views/activity/info.vue'), meta:{title: '活动详情'} },
  {path: '/activitySuccess', name: 'activitySuccess', component: () => import('../views/activity/success.vue'), meta:{title: '活动签到'} },
  {path: '/code-list', name: 'code-list', component: () => import('../views/codeList/index.vue') ,meta:{title:"码上办"}},
  {path: '/house', name: 'house', component: () => import('../views/house/house.vue') ,meta:{title:"房屋列表"}},
  {path: '/house-info', name: 'house-info', component: () => import('../views/house/house-info.vue'), meta:{title: '房屋详情'} },
  {path: '/house-new', name: 'house-new', component: () => import('../views/house-new/house.vue') ,meta:{title:"房屋列表"}},
  {path: '/house-view-details', name: 'house-view-details', component: () => import('../views/house-new/view-details.vue'), meta:{title: '房屋详情', keepAlive: true}},
  {path: '/house-add', name: 'house-add', component: () => import('../views/house-new/house-add.vue'), meta:{title: '新增房屋',  keepAlive: false} },
  
  {path: '/family-info', name: 'family-info', component: () => import('../views/house/family-info.vue'), meta:{title: '家人详情'} },
  {path: '/isolation-info', name: 'isolation-info', component: () => import('../views/house/isolation-info.vue'), meta:{title: '疫情上报'} },
  {path: '/userRes-info', name: 'userResInfo', component: () => import('../views/userRes/info.vue'), meta:{title: '居民信息'} },
  {path: '/userRes-index', name: 'userResIndex', component: () => import('../views/userRes/index.vue'), meta:{title: '居民管理', keepAlive: true,isRefresh:1,} },
  {path: '/userRes-info-basic', name: 'userResInfoBasic', component: () => import('../views/userRes/info-basic.vue'), meta:{title: '居民信息'} },
  {path: '/userHouse-info', name: 'userHouseInfo', component: () => import('../views/userRes/userHouse-info.vue'), meta:{title: '居民房屋信息', keepAlive: false, isRefresh: 0}  },
  {path: '/resCar-add', name: 'resCar-add', component: () => import('../views/userRes/resCar-add.vue'), meta:{title: '居民车辆信息'}  },
  
  {path: '/repair', name: 'repair', component: () => import('../views/repair/repair.vue'), meta:{title: '报修管理'} },
  {path: '/repair-add', name: 'repair-add', component: () => import('../views/repair/repair-add.vue'), meta:{title: '新增报修'} },
  {path: '/repair-info', name: 'repair-info', component: () => import('../views/repair/repair-info.vue'), meta:{title: '报修详情'} },

  {path: '/register-first', name: 'register-first', component: () => import('../views/register/first.vue'), meta:{title: '社区居民自主登记'} },
  {path: '/register', name: 'register', component: () => import('../views/register/register.vue'), meta:{title: '社区居民自主登记'} },
  {path: '/return', name: 'return', component: () => import('../views/register/return.vue'), meta:{title: '返程人员登记'} },
  {path: '/phoneBook', name: 'phoneBook', component: () => import('../views/phoneBook/index.vue'), meta:{title: '便民电话本'} },
  {path: '/houseManagement', name: 'houseManagement', component: () => import('@/views/houseManagement/index.vue'), meta: {title: '房屋管理'}},
  {path: '/peopleList', name: 'peopleList', component: () => import('@/views/houseManagement/peopleList.vue'), meta: {title: '房屋管理',keepAlive: true, refresh: 1}},
  {path: '/peopleAdd', name: 'peopleAdd', component: () => import('@/views/houseManagement/add.vue'),meta: {keepAlive: true,isRefresh: 1}},
  {path: '/familyManagement', name: 'familyManagement', component: () => import('@/views/houseManagement/family.vue'), meta: {title: '家人管理',keepAlive: true, refresh: 1}},
  {path: '/tenantManagement', name: 'tenantManagement', component: () => import('@/views/houseManagement/tenant.vue'), meta: {title: '租客管理',keepAlive: true, refresh: 1}},
  {path: '/car', name: 'car', component: () => import('@/views/car/index.vue'), meta: {title: '车辆管理'}},
  {path: '/car-list', name: 'car', component: () => import('../views/carManage/car.vue'),meta:{title:"车辆管理"}},
  {path: '/car-add', name: 'car-add', component: () => import('../views/carManage/car-add.vue'),meta:{title:"车辆管理"}},
  {path: '/car-illPark', name: 'car-illPark', component: () => import('../views/carManage/car-illPark.vue'),meta:{title:"车辆违停"}},
  
  {path: '/carAdd', name: 'carAdd', component: () => import('@/views/car/add.vue'), meta: {title: '车辆信息'}},
  {path: '/illegallyPark', name: 'illegallyPark', component: () => import('@/views/car/illegallyPark.vue'), meta: {title: '违停记录'}},
  {path: '/illegallyInfo', name: 'illegallyInfo', component: () => import('@/views/car/illegallyInfo.vue'), meta: {title: '违停详情'}},
  {path: '/newRegister', name: 'newRegister', component: () => import('../views/newRegister/first.vue'), meta:{title: '自主登记', keepAlive: true, fromRefresh: 1,isRefresh: 1} },
  {path: '/newRegister-form', name: 'newRegister-form', component: () => import('../views/newRegister/register.vue'), meta:{title: '自主登记', keepAlive: true, isRefresh: 1} },
  {path: '/registerSuccess', name: 'registerSuccess', component: () => import('../views/houseManagement/success.vue'),meta:{title: '登记成功'} },
  {path: '/saveCode' , name: 'saveCode', component: () => import('../views/saveCode/index.vue'),meta:{title: '房东网格'} },
  {path: '/AdminCode' , name: 'AdminCode', component: () => import('../views/saveCode/AdminCode.vue'),meta:{title: '总览',fromRefresh: 1} },
  {path: '/signAContract' , name: 'signAContract', component: () => import('../views/signAContract/index.vue'),meta:{title: '签约信息'} },
  {path: '/signinfo' , name: 'signinfo', component: () => import('../views/signAContract/info.vue'),meta:{title: '签约详情'} },
  {path: '/userReal' , name: 'userReal', component: () => import('../views/saveCode/userReal.vue'),meta:{title: '房屋'} },
  {path: '/saveCode-new' , name: 'saveCode-new', component: () => import('../views/saveCode/new.vue'),meta:{title: '房东网格'} },
  {path: '/saveCode-shop-bind' , name: 'saveCode-shop-bind', component: () => import('../views/saveCode/shop-bind.vue'),meta:{title: '房东网格'} },
  {path: '/saveCode-problem' , name: 'saveCode-problem', component: () => import('../views/saveCode/problem.vue'),meta:{title: '随手报'} },
  {path: '/saveCode-problem-list' , name: 'saveCode-problem-list', component: () => import('../views/saveCode/problem-list.vue'),meta:{title: '随手报'} },
  {path: '/saveCode-help' , name: 'saveCode-help', component: () => import('../views/saveCode/help.vue'),meta:{title: '我要求助'} },
  {path: '/problemInfo', name: 'problemInfo', component: () => import('../views/saveCode/problemInfo.vue'), meta:{title: '问题详情'}},
  {path: '/dealInfo', name: 'dealInfo', component: () => import('../views/saveCode/dealInfo.vue'), meta:{title: '处理详情'}},
  {path: '/dealInfo-help', name: 'dealInfo', component: () => import('../views/saveCode/dealInfo-help.vue'), meta:{title: '处理详情'}},
  {path: '/problem-handling', name: 'problem-handling', component: () => import('../views/saveCode/problem-handling.vue'), meta:{title: '处理问题'}},

  //蓝庭社区
  {path: '/help', name: 'help', component: () => import('../views/lanting/help.vue'),meta:{title:"蓝帮帮"},},

]



const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前
if(to.meta.title){//判断是否有标题
document.title = to.meta.title
}
// if (to.meta.keepAlive && from.meta.refresh) {
//   to.meta.keepAlive = true
//   from.meta.keepAlive = false
// }
next() //执行进入路由，如果不写就不会进入目标页
})

export default router
